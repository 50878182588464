import img_1 from "./source/title.png";
import img_2 from "./source/main.jpg";
import img_3 from "./source/signup.jpg";
import img_4 from "./source/sample.jpg"; 
import img_5 from "./source/image_wala.jpg";

const images = {
    img_1,
    img_2,
    img_3,
    img_4,
    img_5
};


export default images;