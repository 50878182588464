import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { Grid } from "@material-ui/core";

import ProfileHeader from "../components/ProfileHeader";
import PostCard from "../components/PostCard";

import firebase from "../utils/firebase";



const db = firebase.firestore();
const Profile = (props) => {
  const [email, setEmail] = useState("");
  const [postData, setPostData] = useState([]);  
  const [profileDetails, setProfileDetails] = useState({
    user_bio: "",
    user_email: "",
    user_location: "",
    user_name: "",
    user_photo: "",
  });
  
  useEffect(() => {
      //console.log(props.location.user_id);
  }, [])

  useEffect(() => {
    const fetchData = () => {

      db.collection("collection_users")
        .doc(props.location.user_id)
        .collection("profile")
        .onSnapshot((doc) => {
          doc.forEach((c) => {
            setEmail(c.data().user_email);
            setProfileDetails({
              user_bio: c.data().user_bio,
              user_email: c.data().user_email,
              user_location: c.data().user_location,
              user_name: c.data().user_name,
              user_photo: c.data().user_photo,
            });
          });
        });

      db.collection("collection_users")
        .doc(props.location.user_id)
        .collection("posts")
        .onSnapshot((doc) => {
          let foundContents = [];
          doc.forEach((c) => {
            // //console.log(c.id);
            foundContents.push({...c.data(), id: c.id});
          });
          setPostData(foundContents);
        });
    };
    fetchData();
  }, [props.location.user_id]);


  return (
    <div style={{ backgroundColor: "#f0f2f5", paddingBottom: 150 }}>
      <Sidebar />  
      <div className="profile">
        <Grid
          container
          item
          // direction="row"
          // alignItems="center"
          // justify="center"
          // style={{ width: "70%" }}
          xs={11}
          lg={8}
        >
             <Grid item>
            <ProfileHeader email={email} profileDetails={profileDetails} view="yes"/>
            {postData.map((item, key) => (
              <PostCard key={key} item={item} view="yes"/>
            ))}
            </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
